import React from 'react';

class Loading extends React.Component {
  constructor() {
    super();
    this.state = {
      t: 0
    }
    this.prog = [1,2,3,4,5,4,3,2];
  }

  componentWillMount() {
    this.intervalID = setInterval(this.updateDotNumber, 100);
  }

  componentWillUnmount() {
    clearInterval(this.intervalID);
  }

  updateDotNumber = () => {
    this.setState(({ t }) => ({t: t + 1}));
  }
  
  render() {
    return (
      <span>{'o'.repeat(this.prog[this.state.t % this.prog.length])}</span>
    );
  }
}

export default Loading;