import React from 'react';
import { toast } from 'react-toastify';
import { MdEdit } from 'react-icons/md'

import ItemEditor from './ItemEditor.js';
import './GermanItem.css';

const apiUrl = process.env.REACT_APP_API_URL;

class GermanItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editing: false,
      entryID: props.item.entryID,
      czechish: props.item.czechish,
      czech: props.item.czech,
      german: props.item.german
    };
  }

  onEditClicked = () => {
    this.setState({
      editing: true
    });
  }

  onCancelClicked = () => {
    this.setState({
      editing: false
    });
  }

  postEdit = (entry, next) => {
    fetch(apiUrl + '/edit', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        entryID: entry.entryID,
        czechish: entry.czechish,
        czech: entry.czech,
        german: entry.german
      }),
    })
    .then(ret => {
      this.setState({editing: false});
      toast.success('Úprava se zobrazí po schválení ;)', {
        autoClose: 2000
      });
      next(true);
    })
    .catch(err => {
      console.error(err);
      toast('Nejde nám to uložit :(');
      next(false)
    });
  }

  suggestEntryDelete = (entry, next) => {
    fetch(apiUrl + '/edit', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        entryID: entry.entryID,
        czechish: '',
        czech: '',
        german: ''
      }),
    })
    .then(ret => {
      this.setState({editing: false});
      toast.success('Návrh uložen...', {
        autoClose: 2000
      });
      next(true);
    })
    .catch(err => {
      console.error(err);
      toast('Nejde nám to odeslat :(');
      next(false)
    });
  }

  render() {
    if (this.state.editing) {
      return (
        <tr className="animated fadeIn">
          <td colSpan="3">
            <ItemEditor
              item={this.state}
              focused={true}
              label="Navrhnout změnu"
              onSubmitClicked={this.postEdit}
              onDeleteClicked={this.suggestEntryDelete}
              onCancelClicked={this.onCancelClicked}
              ></ItemEditor>
          </td>
        </tr>
      )
    } else {
      return (
        <tr className="animated fadeIn">
          <td className="gi-czechish">
            {this.state.czechish.toUpperCase()}
          </td>
          <td className="gi-czech-german">
            <span className="gi-czech">{this.state.czech}</span>
            <span className="gi-german"> / {this.state.german}</span>
            <span className="edit-button" onClick={this.onEditClicked}>
              <MdEdit />
            </span>
          </td>
        </tr>
      )
    }
  }
}

export default GermanItem;