import React from 'react';

import { DataContext } from '../components/context';

import EditBlock from './EditBlock';
import Loading from '../components/Loading';

export default class EditList extends React.Component {
  static contextType = DataContext;

  componentWillUnmount() {
    this.context.fetchData();
  }

  render() {
    return (
      <div className="list">
        <table>
          <tbody>
            {this.context.loading ?
              <tr><td><Loading /></td></tr> : 
              (this.context.loadingError ? this.getLoadingErrorMsg() : this.getEditBlocks())
            }
          </tbody>
        </table>
      </div>
    );
  }

  getEditBlocks() {
    const pairs = this.getPairs();
    if (pairs.length) {
      return pairs.map(p => <EditBlock key={p.entry.entryID + '' + p.edit.entryEditID} original={p.entry} edit={p.edit} />);
    } else {
      return (<tr><td><strong>Žádné úpravy...</strong></td></tr>);
    }
  }

  getPairs() {
    const pairs = [];

    for(const entry of this.context.entries) {
      const entryEdits = this.context.edits.filter(ed => ed.entryID === entry.entryID);

      if (entryEdits.length) {
        for (const edit of entryEdits) {
          pairs.push({
            entry: entry,
            edit: edit
          });
        }
      }
    }

    return pairs;
  }

  getLoadingErrorMsg() {
    return (
      <tr>
        <td>
          <strong style={{color: 'rgb(0, 150, 221)', cursor: 'pointer'}} onClick={() => this.context.fetchData()}>Zkusit znovu...</strong>
        </td>
      </tr>
    );
  }
}
