import React from 'react';
import { toast } from 'react-toastify';

import { DataContext } from '../components/context';

import EntryBlock from './EntryBlock'
import './edit-block.css';

const apiUrl = process.env.REACT_APP_API_URL;

export default class EditBlock extends React.Component {
  static contextType = DataContext;

  constructor(props) {
    super(props);
    this.state = {
      original: props.original,
      edit: props.edit
    };
  }

  acceptEdit = () => {
    fetch(apiUrl + `/edit/apply?entryEditID=${encodeURIComponent(this.state.edit.entryEditID)}`)
    .then(ret => {
      toast.success('Úprava provedena :)', {
        autoClose: 2000
      });
      if (this.context.removeEdit) {
        this.context.removeEdit(this.state.edit.entryEditID);
      }
    })
    .catch(err => {
      console.error(err);
      toast('Nejde nám to upravit :(');
    });
  }

  rejectEdit = () => {
    fetch(apiUrl + '/edit/' + this.state.edit.entryEditID, { method: 'DELETE' })
    .then(ret => {
      toast.success('Úprava vymazána', {
        autoClose: 2000
      });
      if (this.context.removeEdit) {
        this.context.removeEdit(this.state.edit.entryEditID);
      }
    })
    .catch(err => {
      console.error(err);
      toast('Nejde nám to vymazat :(');
    });
  }

  deleteEntry = () => {
    fetch(apiUrl + '/' + this.state.edit.editID, { method: 'DELETE' })
    .then(ret => {
      toast.success('Vymazáno :)', {
        autoClose: 2000
      });
      if (this.context.removeEntry) {
        this.context.removeEntry(this.state.edit.entryID);
      }
    })
    .catch(err => {
      console.error(err);
      toast('Nejde nám to vymazat :(');
    });
  }

  render() {
    return (
      <tr className="eb-row">
        <td className="eb-orig" onClick={this.rejectEdit}><EntryBlock entry={this.state.original} /></td>
        <td style={{textAlign: 'center', width: '64px'}}>-></td>
        <td className="eb-edit" onClick={this.acceptEdit}><EntryBlock entry={this.state.edit} /></td>
      </tr>
    );
  }
}
