import React from 'react';
import { Link } from 'react-router-dom';
import { ToastContainer, Slide } from 'react-toastify';

import EditList from './EditList';

export default function Admin() {
  return (
    <div className="Admin">
      <h1>Admin <Link to="/">(back to App)</Link></h1>
      
      <br/>

      <EditList />

      <ToastContainer
        position={window.innerWidth >= 801 ? 'bottom-center' : 'top-right'}
        autoClose={5000}
        transition={Slide}
        hideProgressBar={true}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnVisibilityChange
        draggable
        pauseOnHover
        />
    </div>
  );  
}
