class TextUtil {
  static getDiacriticComparator(mappingFunction) {
    return (str1, str2) => this.normalizeName(mappingFunction(str1)) > this.normalizeName(mappingFunction(str2)) ? 1 : -1;
  }

  static normalizeName(str) {
    if (str === '' || str === ' ') {
      return 'zzzzzzzzzzzzzzzz';
    }
    return (
      str
        .trim()
        .toLowerCase()
        .replace(/á/g, 'azzz')
        .replace(/é/g, 'ezzz')
        .replace(/í/g, 'izzz')
        .replace(/ý/g, 'yzzz')
        .replace(/ó/g, 'ozzz')
        .replace(/ú/g, 'uzzz')
        .replace(/ů/g, 'uzzz')
        .replace(/ü/g, 'uzzz')
        .replace(/č/g, 'czzz')
        .replace(/ď/g, 'dzzz')
        .replace(/ě/g, 'ezzz')
        .replace(/ň/g, 'nzzz')
        .replace(/ř/g, 'rzzz')
        .replace(/š/g, 'szzz')
        .replace(/ť/g, 'tzzz')
        .replace(/ž/g, 'zzzz')
    );
  }
}

export default TextUtil;