import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import App from './App';
import Admin from './Admin/Admin'
import Footer from './components/Footer';

import { DataContext } from './components/context';

export default class AppRouter extends React.Component{
  static contextType = DataContext;

  render() {
    return (
      <Router>
        <div>
          <Route path="/" exact component={App} />
          <Route path="/trololo/" component={Admin} />
        </div>
        { !(this.context.loading || this.context.loadinError) && <Footer /> }
      </Router>
    );
  }
}
