import React, { Component } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const apiUrl = process.env.REACT_APP_API_URL;

const DataContext = React.createContext();
const DataConsumer = DataContext.Consumer;

class DataProvider extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      loadingError: false,
      entries: [],
      edits: [],
      fetchData: this.fetchData,
      removeEntry: this.removeEntry,
      removeEdit: this.removeEdit
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData = () => {
    this.setState({
      loading: true,
      loadingError: false,
      entries: [],
      edits: []
    });

    setTimeout(() => {
      this.fetchEntries();
    }, 500);
  }

  fetchEntries = () => {
    fetch(apiUrl)
      .then(data => data.json())
      .then(data => {
        this.setState({
          loadingError: false,
          entries: data,
        });
        this.fetchEdits();
      })
      .catch(err => {
        console.error(err);
        this.setState({
          loading: false,
          loadingError: true,
          entries: [],
          edits: []
        });
        toast.error('Nepodařilo se načíst data...');
      });
  }

  fetchEdits = () => {
    fetch(apiUrl + '/edit')
      .then(data => data.json())
      .then(data => this.setState({
          loading: false,
          loadingError: false,
          edits: data}))
      .catch(err => {
        console.error(err);
        this.setState({
          loading: false,
          loadingError: true,
          entries: [],
          edits: []
        });
        toast.error('Nepodařilo se načíst data...');
      });
  }

  removeEntry = (entryID) => {
    this.setState(({ entries }) => ({entries: entries.filter(e => e.entryID !== entryID)}));
  }

  removeEdit = (entryEditID) => {
    this.setState(({ edits }) => ({edits: edits.filter(e => e.entryEditID !== entryEditID)}));
  }

  render() {
    return (
      <DataContext.Provider value={this.state}>
        { this.props.children }
      </DataContext.Provider>
    );
  }
}

export { DataProvider, DataConsumer, DataContext };