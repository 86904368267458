import React from 'react';

export default function EntryBlock({ entry }) {
  if (entry) {
    if (!entry.czechish && !entry.czech && !entry.german) {
      return (
        <span>SMAZAT</span>
      );
    } else {
      return (
        <span>
          {entry.czechish}
          <br/>
          {entry.czech}
          <br/>
          {entry.german}
        </span>
      );
    }
  } else {
      return (
        <span>
          N/A
        </span>
      );
  }
}
