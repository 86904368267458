import React from 'react';
import { FaSearch, FaTimes } from 'react-icons/fa'

import './AwesomeSearchBar.css';

class AwesomeSearchBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      text: '',
      focused: false
    }
  }

  onTextChange = (event) => {
    this.setState({text: event.target.value});
    if (this.props.onTextChange) {
      this.props.onTextChange(event.target.value);
    }
  }

  onFocus = () => {
    this.setState({focused: true});
  }

  onBlur = () => {
    this.setState({focused: false});
  }

  resetText = () => {
    this.setState({text: ''});
    if (this.props.onTextChange) {
      this.props.onTextChange('');
    }
  }

  handleKeyUp = (event) => {
    if (event.keyCode === 27) {
      this.resetSearchBar();
    }
  }

  resetSearchBar = () => {
    this.resetText();
    try {
      document.getElementById('superInput').blur();
    } catch (error) { }
  }

  render() {
    return (
      <div className={"AwesomeSearchBar" + ((this.state.focused || this.state.text) && " focused")} onKeyUp={this.handleKeyUp}>
        <div className="search-icon-container">
          <FaSearch className="icon"/>
        </div>
        <div className="input-container">
          <input
            id="superInput"
            type="text"
            value={this.state.text}
            onChange={this.onTextChange}
            onFocus={this.onFocus}
            onBlur={this.onBlur}
            placeholder={this.props.placeholder}
          />
        </div>
        <div className="border-container">
          <div className="border"></div>
        </div>
        <div className="reset-icon-container">
          <FaTimes
            className="icon no-border"
            onClick={this.resetText}
          />
        </div>
      </div>
      );
  }
}

export default AwesomeSearchBar
