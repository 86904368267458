import React from 'react';
import './ItemEditor.css';

class ItemEditor extends React.Component {
  constructor(props) {
    super(props);

    const { focused, label, item } = props;

    this.state = {
      focused: focused,
      saved: false,
      label: label && label,
      entryID: item && item.entryID,
      czechish: item ? item.czechish : '',
      czech: item ? item.czech : '',
      german: item ? item.german : ''
    };
  }

  onAddClicked = () => {
    this.setState({focused: true});
  }

  onCancelClicked = () => {
    this.setState({focused: false, czechish: '', czech: '', german: ''});
    if (this.props.onCancelClicked) {
      this.props.onCancelClicked();
    }
  }
  
  onSubmitClicked = () => {
    if (this.props.onSubmitClicked) {
      this.props.onSubmitClicked(this.state, (res) => {
        if (res) {
          this.onCancelClicked();
        }
      })
    } else {
      console.error("Function onSubmitClicked should have been passed in props. ");
    }
  }
  
  onDeleteClicked = () => {
    if (this.props.onDeleteClicked) {
      this.props.onDeleteClicked(this.state, (res) => {
        if (res) {
          this.onCancelClicked();
        }
      })
    } else {
      console.error("Function onDeleteClicked should have been passed in props. ");
    }
  }

  changeHandler = (e) => {
    this.setState({[e.target.name]: e.target.value});
  };

  render() {
    if (this.state.focused) {
      return (
        <div className="ItemEditor">
          { this.state.label && <div style={{textAlign: 'left', paddingLeft: '32px', color: '#aaa'}}>{this.state.label}</div> }
          <div className="editor-inputs">
            <input
              type="text"
              name="czechish"
              className="czechish lr"
              placeholder="not czech"
              value={this.state.czechish}
              onChange={this.changeHandler}
            />
            <input
              type="text"
              name="czech"
              className="czech black"
              placeholder="czech"
              value={this.state.czech}
              onChange={this.changeHandler}
            />
            <input
              type="text"
              name="german"
              className="german"
              placeholder="german"
              value={this.state.german}
              onChange={this.changeHandler}
            />
          </div>
          <button onClick={this.onSubmitClicked} disabled={!this.state.czechish.length}>Uložit</button>
          <button onClick={this.onCancelClicked}>Zavřít</button>
          {this.props.onDeleteClicked ? <button onClick={this.onDeleteClicked}>Smazat</button> : null}
        </div>
      )
    } else {
      return (
        <div>
          <strong
            className="button show-button"
            onClick={this.onAddClicked}
          >+</strong>
        </div>
      )
    }
  }
}

export default ItemEditor;