import React from 'react';
import { ToastContainer, toast, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { DataContext } from './components/context';

import './App.css';
import GermanItem from './components/GermanItem';
import ItemEditor from './components/ItemEditor';
import Loading from './components/Loading';
import TextUtil from './utils/TextUtil';
import AwesomeSearchBar from './components/AwesomeSearchBar/AwesomeSearchBar';

const apiUrl = process.env.REACT_APP_API_URL;

class App extends React.Component {
  static contextType = DataContext;

  state = {
    searchText: ''
  }

  componentWillUnmount() {
    this.context.fetchData();
  }

  render() {
    return (
      <div className="App">
        <h1>Is this Czech</h1>
        <div className="list">
          <div className="search-bar-container">
            <AwesomeSearchBar onTextChange={this.onSearchTextChange} placeholder=""/>
          </div>
          {!(this.context.loading || this.context.loadingError) && <ItemEditor loadData={this.loadData} onSubmitClicked={this.postNewEntry}></ItemEditor>}
          <table>
            <tbody>
              {this.context.loading ? <tr><td><Loading /></td></tr> : 
                this.context.loadingError ? this.getLoadingErrorMessage() : this.getEntryItems()
              }
            </tbody>
          </table>
        </div>
        <ToastContainer
          position={window.innerWidth >= 801 ? 'bottom-center' : 'top-right'}
          autoClose={5000}
          transition={Slide}
          hideProgressBar={true}
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnVisibilityChange
          draggable
          pauseOnHover
          />
      </div>
    );
  }

  getEntryItems() {
    const sortedEntries = this.getSortedEntries();
    return sortedEntries.map(item => <GermanItem key={item.entryID} item={item} />);
  }

  getSortedEntries() {
    const entries = this.getSearchFilteredEntries();
    return entries.sort(TextUtil.getDiacriticComparator(e => e.czechish));
  }

  getSearchFilteredEntries() {
    const { entries } = this.context;
    const { searchText } = this.state;

    if (searchText) {
      const regex = new RegExp(`^${searchText}`, 'i');
      return entries.filter(e => e.czechish.match(regex));
    } else {
      return entries;
    }
  }
  
  getLoadingErrorMessage() {
    return (
      <tr>
        <td>
          <strong style={{color: 'rgb(0, 150, 221)', cursor: 'pointer'}} onClick={() => this.context.fetchData()}>Zkusit znovu...</strong>
        </td>
      </tr>
    );
  }
  
  onSearchTextChange = (searchText) => {
    this.setState({searchText: searchText.trim()});
  }

  postNewEntry = (entry, next) => {
    fetch(apiUrl, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        entryID: null,
        czechish: entry.czechish,
        czech: entry.czech,
        german: entry.german
      }),
    })
    .then(ret => {
      this.context.fetchData();
      toast.success('Uloženo :)', {
        autoClose: 2000
      });
      next(true);
    })
    .catch(err => {
      console.error(err);
      toast('Nejde nám to uložit :(');
      next(false)
    });
  }
}

export default App;
